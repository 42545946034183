import styled from "@emotion/styled"
import { ChevronLeft } from "@mui/icons-material" // Import ChevronLeft icon
import { IconButton, Input, Modal } from "@mui/material"
import { useQuery } from "@tanstack/react-query"
import { useLocale } from "next-intl"
import Image from "next/image"
import { usePathname } from "next/navigation"
import { useState } from "react"
import { Culture } from "utils"
import { CloseCircleIcon, XIcon } from "../icons"
import Results from "./Results"

import { useEffect } from "react"

const StyledContainer = styled.div`
  position: relative;
`

const StyledModalBox = styled.div<{ hasBottomRadius: boolean }>`
  border-radius: ${(props) =>
    props.hasBottomRadius ? "16px 16px 0px 0px" : "16px"};
  position: absolute;
  top: 106px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100vw - 32px);
  height: 72px;
  background-color: white;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.16);
  padding: 16px;
  margin: 0 auto;
  input {
    padding: 0px;
    height: 100%;
    &::placeholder {
      color: #6f6f6f;
    }
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    top: 140px;
    width: 1040px;
  }
`

const StyledCloseIcon = styled.div`
  position: absolute;
  top: 70px;
  right: 50%;
  transform: translateX(50%);
  width: calc(100vw - 32px);
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;

  @media (min-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    top: 105px;
    width: 1040px;
  }
  button {
    padding: 0px;
  }
  svg {
    width: 28px;
    height: 28px;
  }
`

type Props = {
  open: boolean
  handleClose: () => void
  culture: Culture
  pathString: string
  idNumber: string[]
  searchRouteString: string
}

type Result = {
  items: []
}
const fetchSearchResults = async (text: string, path: string) => {
  // Replace this with the actual API call
  const GRAPHQL_URL = process.env.GRAPHQL_URL?.replace("/graphql", "")
  const PROJECTS_PATH = process.env.ENV?.includes("production")
    ? "-1,4802,4823,4861"
    : "-1,1540,1543,1556"

  const fetchPages = fetch(
    `${GRAPHQL_URL}/generic/search?path=${path}&term=${text}`
  )
  const fetchProjects = fetch(
    `${GRAPHQL_URL}/generic/search?path=${PROJECTS_PATH}&term=${text}&pageSize=1000`
  )
  const [pagesJSON, projectsJSON] = await Promise.all([
    fetchPages,
    fetchProjects,
  ])
  const pages: Result = await pagesJSON.json()
  const projects: Result = await projectsJSON.json()

  return { pages, projects }
}

export const SearchModal = ({
  open,
  handleClose,
  culture,
  idNumber,
  pathString,
  searchRouteString,
}: Props) => {
  const [text, setText] = useState<string>("")
  const [debouncedText, setDebouncedText] = useState<string>(text)
  const close = () => {
    handleClose()
    setText("")
    setDebouncedText("")
  }
  const clear = () => {
    setText("")
    setDebouncedText("")
  }
  const locale = useLocale()

  const pathname = usePathname()

  useEffect(() => {
    close()
  }, [pathname])

  const { data, isFetching } = useQuery({
    queryKey: ["search", debouncedText],
    queryFn: () => fetchSearchResults(debouncedText, pathString),
    enabled: !!debouncedText,
    refetchOnWindowFocus: false,
  })
  const pageIDs =
    (data?.pages?.items?.map((el: any) => el.id) as number[]) ?? []
  const projectIDs =
    (data?.projects?.items?.map((el: any) => el.id) as number[]) ?? []
  return (
    <Modal open={open} onClose={close}>
      <StyledContainer>
        <StyledCloseIcon>
          <IconButton onClick={() => close()}>
            <CloseCircleIcon />
          </IconButton>
        </StyledCloseIcon>
        <StyledModalBox hasBottomRadius={!!debouncedText}>
          <Input
            onChange={(e) => setText(e.target.value)}
            value={text}
            sx={{
              "&.MuiInput-root:before": {
                borderBottom: "none",
              },
              "&.MuiInput-root:after": {
                borderBottom: "none",
              },
              "&.MuiInput-root:hover:not(.Mui-disabled):before": {
                borderBottom: "none",
              },
            }}
            inputProps={{ "aria-label": "הקלד מילות חיפוש" }}
            fullWidth
            placeholder={locale === "he" ? "חיפוש" : "Search"}
            style={{ height: "40px", padding: 0 }}
            startAdornment={
              debouncedText ? (
                <IconButton onClick={clear}>
                  <XIcon />
                </IconButton>
              ) : (
                <div
                  style={{
                    padding: "8px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Image
                    src="/searchicon.png"
                    alt="Search"
                    width={24}
                    height={24}
                  />
                </div>
              )
            }
            endAdornment={
              <IconButton
                style={{
                  background: "#2768A3",
                  color: "white",
                  transform: locale === "en" ? "rotate(180deg)" : "none",
                }}
                aria-label="חפש"
                onClick={() => setDebouncedText(text)}
              >
                <ChevronLeft color="inherit" />
              </IconButton>
            }
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                setDebouncedText(text)
              }
            }}
          />
        </StyledModalBox>
        {debouncedText?.length > 0 ? (
          <Results
            pageIDs={pageIDs}
            projectIDs={projectIDs}
            handleClose={close}
            culture={culture}
            isLoadingQuery={isFetching}
            word={debouncedText}
            idNumber={idNumber}
            searchRouteString={searchRouteString}
          />
        ) : null}
      </StyledContainer>
    </Modal>
  )
}
